<template>
  <div class="moose">
    <div ref="moose" class="moose-wrapper">
      <div class="moose-size">
        <!-- <img src="./../assets/body.png" alt class="body" /> -->
        <div class="head" ref="header">
          <img src="./../assets/moose.png" alt />
          <img src="./../assets/glasses.png" alt class="glasses" />
          <img src="./../assets/nose.png" alt class="nose" />
          <img src="./../assets/ear.png" alt class="ear-left" />
          <img src="./../assets/ear.png" alt class="ear-right" />
          <div class="mouse"></div>
          <div class="ho">
            <img src="./../assets/ho.svg" alt class="ho-img" />
            <img src="./../assets/ho.svg" alt class="ho-img" />
            <img src="./../assets/ho.svg" alt class="ho-img" />
            <img src="./../assets/MerryXmas.svg" alt class="xmas" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "moose",
  mounted() {
    const targets = this.$refs.moose;
    this.$anime
      .timeline({
        loop: false,
        delay: 6000,
      })
      .add({
        targets: targets,
        translateY: 200,
      });

    this.$anime
      .timeline({
        duration: 1000,
        easing: "easeOutExpo",
      })
      .add({
        targets,
        translateY: 1000,
      })
      .add({
        targets,
        delay: 5800,
        translateY: 0,
      });

    this.$anime
      .timeline({
        loop: true,
      })
      .add({
        targets: ".head",
        translateY: 10,
        duration: 1000,
        rotateZ: -1,
        delay: 0,
        direction: "reverse",
      })
      .add({
        targets: ".head",
        translateY: 0,
        rotateZ: 1,
        duration: 1000,
      });
    this.$anime
      .timeline({
        loop: true,
      })
      .add({
        targets: ".ear-left",
        rotateZ: 0,
        delay: 3000,
        duration: 500,
      })
      .add({
        targets: ".ear-left",
        rotateZ: 5,
        duration: 100,
      })
      .add({
        targets: ".ear-left",
        rotateZ: 0,
        duration: 100,
      })
      .add({
        targets: ".ear-left",
        rotateZ: 5,
        duration: 100,
      })
      .add({
        targets: ".ear-left",
        rotateZ: 0,
        duration: 100,
      })
      .add({
        targets: ".ear-left",
        rotateZ: 5,
        duration: 100,
      })
      .add({
        targets: ".ear-left",
        rotateZ: 0,
        duration: 100,
      })
      .add({
        targets: ".ear-right",
        duration: 100,
        rotateZ: 0,
      })
      .add({
        targets: ".ear-right",
        duration: 100,
        rotateZ: -5,
      })
      .add({
        targets: ".ear-right",
        duration: 100,
        rotateZ: 0,
      })
      .add({
        targets: ".ear-right",
        duration: 100,
        rotateZ: -5,
      })
      .add({
        targets: ".ear-right",
        duration: 100,
        rotateZ: 0,
      });
    // this.$anime
    //   .timeline({
    //     loop: true,
    //   })
    //   .add({
    //     targets: ".nose",
    //     rotateY: 3,
    //     rotateX: 10,
    //     rotateZ: -10,
    //     duration: 2000,
    //     delay: 500,
    //   })
    //   .add({
    //     targets: ".nose",
    //     rotateY: 3,
    //     rotateX: 10,
    //     rotateZ: -10,
    //     duration: 2000,
    //     delay: 500,
    //   });
    this.$anime
      .timeline({
        loop: true,
      })
      .add({
        targets: document.querySelectorAll(".ho-img")[0],
        translateY: -50,
        translateX: -20,
        duration: 1100,
        opacity: 1,
      })
      .add({
        targets: document.querySelectorAll(".ho-img")[0],
        translateY: -100,
        translateX: -50,
        duration: 1900,
        opacity: 0,
      })
      .add({
        targets: document.querySelectorAll(".ho-img")[0],
        duration: 5000,
        opacity: 0,
      });
    this.$anime
      .timeline({
        loop: true,
      })
      .add({
        targets: document.querySelectorAll(".ho-img")[1],
        translateY: -50,
        translateX: -20,
        duration: 1200,
        opacity: 1,
      })
      .add({
        targets: document.querySelectorAll(".ho-img")[1],
        translateY: -100,
        translateX: -50,
        duration: 1800,
        opacity: 0,
      })
      .add({
        targets: document.querySelectorAll(".ho-img")[1],
        duration: 5000,
        opacity: 0,
      });
    this.$anime
      .timeline({
        loop: true,
      })
      .add({
        targets: document.querySelectorAll(".ho-img")[2],
        translateY: -50,
        translateX: -20,
        duration: 1300,
        opacity: 1,
      })
      .add({
        targets: document.querySelectorAll(".ho-img")[2],
        translateY: -100,
        translateX: -50,
        duration: 1700,
        opacity: 0,
      })
      .add({
        targets: document.querySelectorAll(".ho-img")[2],
        duration: 5000,
        opacity: 0,
      });
    this.$anime
      .timeline({
        loop: true,
      })
      .add({
        targets: document.querySelectorAll(".xmas"),
        translateY: 0,
        translateX: 0,
        duration: 4000,
        opacity: 1,
      })
      .add({
        targets: document.querySelectorAll(".xmas"),
        translateY: 0,
        translateX: 0,
        duration: 4000,
        opacity: 1,
      })
      .add({
        targets: document.querySelectorAll(".xmas"),
        translateY: -150,
        translateX: -180,
        duration: 4000,
        opacity: 0,
      });
  },
};
</script>

<style>
</style>