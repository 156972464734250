<template>
  <div class="flower is-red">
    <svg
      viewBox="0 0 360 360"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M359.632848,180.030199 C359.632848,203.089272 336.698543,221.423046 328.383576,241.474172 C319.764238,262.261192 322.69351,291.363974 307.028344,307.028344 C291.363974,322.69351 262.261192,319.764238 241.474172,328.383576 C221.423046,336.698543 203.089272,359.632848 180.030199,359.632848 C156.971126,359.632848 138.637351,336.698543 118.586225,328.383576 C97.7992053,319.764238 68.6964238,322.69351 53.0312583,307.028344 C37.3668874,291.363179 40.2961589,262.261192 31.6768212,241.474172 C23.3618543,221.423046 0.427549669,203.089272 0.427549669,180.030199 C0.427549669,156.971126 23.3618543,138.637351 31.6768212,118.586225 C40.2961589,97.7992053 37.3668874,68.6964238 53.032053,53.032053 C68.6964238,37.3668874 97.7992053,40.2961589 118.586225,31.6768212 C138.637351,23.3618543 156.971126,0.427549669 180.030199,0.427549669 C203.089272,0.427549669 221.423046,23.3618543 241.474172,31.6768212 C262.261192,40.2961589 291.363974,37.3668874 307.029139,53.032053 C322.69351,68.6972185 319.764238,97.7992053 328.383576,118.586225 C336.698543,138.637351 359.632848,156.971126 359.632848,180.030199"
          id="flower-red-path-1"
        />
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="flower-red">
          <g
            id="Fill-24-+-Fill-3-+-Fill-6-+-Fill-9-+-Fill-12-+-Fill-15-+-Fill-18-+-Fill-21-Mask"
            transform="translate(0.000079, -0.000079)"
          >
            <mask id="flower-red-mask-2" fill="white">
              <use xlink:href="#flower-red-path-1" />
            </mask>
            <use id="Mask" fill="#D24008" xlink:href="#flower-red-path-1" />
          </g>
          <path
            d="M144,180 C144,160.951333 159.396254,145.508982 178.38806,145.508982 C197.379865,145.508982 212.776119,160.951333 212.776119,180 C212.776119,199.048667 197.379865,214.491018 178.38806,214.491018 C159.396254,214.491018 144,199.048667 144,180"
            fill="#AB2128"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "FlowerRed",
};
</script>

<style lang="scss">
@keyframes speedRotate {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(180deg);
  }
  70% {
    transform: rotate(-540deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

.flower.is-red {
  animation: speedRotate 10s ease forwards infinite;
}
</style>