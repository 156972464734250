<template>
  <div id="app">
    <div class="filter"></div>
    <main>
      <aside>AJA Creative</aside>
      <!-- <h1 class="year">2021</h1> -->
      <section>
        <header>
          <h1>
            <img src="./assets/BestWishes.svg" alt />
          </h1>
        </header>
        <div id="scene" class="wrapper">
          <div data-depth="0.2" class="circle"></div>
          <div data-depth="0.3" class="sparks">
            <Spark />
            <Spark />
            <Spark />
          </div>
          <div data-depth="0.5" class="flowers">
            <FlowerRed />
            <FlowerPink />
            <FlowerRed />
            <FlowerPink />
          </div>
          <div data-depth="0.4" class="leaves">
            <div class="left-leafs">
              <Leave />
              <Leave :flip="true" />
              <Leave :color="`green`" />
              <Leave :color="`green`" :flip="true" />
              <Leave />
              <Leave :flip="true" />
              <Leave :color="`green`" />
              <Leave :color="`green`" :flip="true" />
              <Leave />
              <Leave :flip="true" />
            </div>
            <div class="right-leafs">
              <Leave :color="`green`" />
              <Leave :color="`green`" :flip="true" />
              <Leave />
              <Leave :flip="true" />
              <Leave :color="`green`" />
              <Leave :color="`green`" :flip="true" />
              <Leave />
              <Leave :flip="true" />
              <Leave :color="`green`" />
              <Leave :color="`green`" :flip="true" />
            </div>
          </div>
          <Moose />
          <img src="./assets/rolling.png" alt class="rolling" />

          <div class="wording">
            <div class="left">
              辛苦的一年我們創造回憶
              <br />即將春暖花開之際
            </div>
            <div class="right">
              願新的一年充滿生機
              <br />一切順心 快樂向前行
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer>
      <button class="close" @click="closeFooter"></button>
      <div class="whatwedo" @click="openFooter">
        回顧我們在 2020年 做了什麼！
        <img src="./assets/spark.svg" alt="spark" />
      </div>
      <ul>
        <li>
          <a href="https://aja.com.tw/starlux-case-study/index.html" target="_blank">星宇航空數位體驗案</a>
        </li>
        <li>
          <a href="http://fetnet-revision.aja.com.tw/" target="_blank">遠傳電信官網改版</a>
        </li>
        <li>
          <a class="disabled" href="#" target="_blank">yoxi 和泰全新派遣服務</a>
          <img class="coming" src="./assets/Commingsoon.svg" alt="coming soon" />
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
import Moose from "./components/Moose.vue";
import Parallax from "parallax-js";
import Leave from "./components/Leave.vue";
import Spark from "./components/Spark.vue";
import FlowerRed from "./components/FlowerRed.vue";
import FlowerPink from "./components/FlowerPink.vue";
export default {
  name: "App",
  components: {
    Leave,
    FlowerRed,
    FlowerPink,
    Spark,
    Moose,
  },
  methods: {
    openFooter(e) {
      e.preventDefault();

      const foot = document.querySelector("footer");
      if (window.innerWidth < 1024) {
        foot.classList.add("is-open");
      }
    },
    closeFooter(e) {
      e.preventDefault();

      const foot = document.querySelector("footer");
      if (window.innerWidth < 1024) {
        foot.classList.remove("is-open");
      }
    },
  },
  mounted() {
    var scene = document.getElementById("scene");
    new Parallax(scene);

    const leaf = Array.from(document.querySelectorAll(".leave"));
    const flowers = Array.from(document.querySelectorAll(".flower"));

    setInterval(() => {
      leaf.forEach((dom, i) => {
        // console.log(dom)
        setTimeout(() => {
          if (!dom.classList.contains("is_reverse")) {
            dom.classList.add("is_reverse");
          } else {
            dom.classList.remove("is_reverse");
          }
        }, (i + 1) * 250);
      });
    }, 7000);

    this.$anime.timeline().add({
      targets: ".wording",
      delay: 6500,
      duration: 2000,
      opacity: 1,
      easing: "easeInOutExpo",
    });

    this.$anime.timeline().add({
      targets: flowers[0],
      delay: 7000,
      duration: 500,
      top: window.innerWidth < 960 ? -160 : -120,
      left: window.innerWidth < 960 ? 106 : -150,
      easing: "easeInOutExpo",
    });
    this.$anime.timeline().add({
      targets: flowers[1],
      delay: 7000,
      duration: 500,
      top: window.innerWidth < 960 ? -80 : -120,
      left: window.innerWidth < 960 ? 8 : -150,
      easing: "easeInOutExpo",
    });

    this.$anime.timeline().add({
      targets: flowers[2],
      delay: 6500,
      duration: 500,
      top: window.innerWidth < 960 ? -80 : -120,
      left: window.innerWidth < 960 ? -20 : 150,
      easing: "easeInOutExpo",
    });
    this.$anime.timeline().add({
      targets: flowers[3],
      delay: 6500,
      duration: 500,
      top: window.innerWidth < 960 ? -160 : -120,
      left: window.innerWidth < 960 ? -116 : 150,
      easing: "easeInOutExpo",
    });

    this.$anime.timeline().add({
      targets: ".year",
      innerHTML: [0, 2021],
      round: 1,
      duration: 3000,
      easing: "easeInOutExpo",
    });

    this.$anime
      .timeline()
      .add({
        targets: ".rolling",
        opacity: 0,
        easing: "easeInOutExpo",
      })
      .add({
        targets: ".rolling",
        opacity: 1,
        duration: 3000,
        delay: 7000,
        easing: "easeInOutExpo",
      });
  },
};
</script>
<style lang="sass" src="./assets/style/main.sass"/>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,900&display=swap");
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}
.filter {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/pixel-bg.jpg");
  // background-size: cover;
  background-position: center;
  mix-blend-mode: overlay;
  opacity: 0.1;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  height: 100vh;
  overflow: hidden;
  background: #134228;
  display: flex;
  flex-direction: column;

  main {
    display: flex;
    flex: 1;
    flex-grow: 1;

    section {
      flex: 1;
      flex-grow: 1;
    }
  }

  footer {
    position: relative;
    display: flex;
    color: white;
    background: #272c2f;
    padding: 0 32px;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-top: 2px solid #c99d52;
    z-index: 2;

    .close {
      display: none;
    }
    * {
      align-content: center;
      align-items: center;
      font-weight: 500;
    }

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      > li {
        position: relative;
        display: inline-flex;
        margin-left: 32px;
        align-content: center;

        &::before {
          content: "";
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url(assets/list-style.svg) no-repeat center;
          background-size: 24px;
          margin-right: 16px;
        }
      }

      a {
        display: inline-block;
        color: white;
        text-decoration: none;
        letter-spacing: 1px;
        font-size: 16px;
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
        height: 78px;
        font-weight: 400;
        line-height: 78px;
        border-bottom: 2px solid transparent;
        font-family: "Montserrat";
        &:hover,
        &:focus {
          border-bottom: 2px solid #c99d52;
        }
        &.disabled {
          color: #5e676d;
          pointer-events: none;
        }
      }
      .coming {
        width: 124px;
        margin-left: 8px;
        margin-top: -7px;
      }
    }
  }
}

@keyframes bgMarquee {
  0% {
    background-position: top 0px left 12px;
  }
  100% {
    background-position: top 243px left 12px;
  }
}

@keyframes bgMarqueeM {
  0% {
    background-position: top 7px left 0px;
  }
  100% {
    background-position: top 7px left 180px;
  }
}

aside {
  display: block;
  width: 80px;
  overflow: hidden;
  border-right: 2px solid #c99d52;
  background: url(assets/aja-logo.png) top 0px center repeat-y;
  background-size: 49px;
  text-indent: -9999px;
  animation: bgMarquee 5s linear infinite;
}
.rolling {
  top: auto !important;
  left: auto !important;
  right: 19%;
  bottom: 16%;
  z-index: 11;
  width: 260px;
  animation: rolling 40s infinite;
  @media screen and (max-width: 960px) {
    display: none !important;
  }
  @keyframes rolling {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-3600deg);
    }
  }
}
.whatwedo {
  min-width: 306px;
  font-size: 18px;
  img {
    display: none;
    width: 22px;
    margin-bottom: -4px;
  }
  @media screen and (max-width: 960px) {
    img {
      display: inline-block;
    }
  }
}
.wording {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  z-index: 11;
  opacity: 0;

  .left {
    left: 8%;
  }
  .right {
    right: 8%;
  }
  .left,
  .right {
    position: absolute;
    top: 12%;
    writing-mode: vertical-lr;
    text-align: left;
    font-size: 18px;
    line-height: 86px;
    letter-spacing: 5px;
    font-weight: 400;
  }

  @media screen and (max-width: 1200px) {
    .left {
      left: 15px;
    }
    .right {
      right: 15px;
    }
  }

  @media screen and (max-width: 960px) {
    .left,
    .right {
      top: 5%;
      margin-top: 0;
      font-size: 16px;
      line-height: 35px;
    }
  }
}

main {
  position: relative;
  display: block;
  overflow: hidden;

  section {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    header {
      padding: 0 20px;
      border-bottom: 2px solid #c99d52;

      @media screen and (min-width: 1200px) {
        padding: 0 80px;
      }
    }

    h1 {
      display: block;
      height: 5vh;
      text-indent: -9999px;
      background-image: url(assets/BestWishes.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @media screen and (min-width: 1200px) {
        height: 16vh;
      }
      svg {
        max-width: 100%;
      }
    }

    .wrapper {
      position: relative;
      flex: 1;
      flex-grow: 1;
      // overflow: hidden;
      bottom: 0;
    }
  }

  .circle {
    position: absolute !important;
    // top: auto !important;
    top: 15% !important;
    // bottom: -250vh;
    left: -100% !important;
    right: -100% !important;
    margin: 0 auto;
    width: 1170px;
    height: 1167px;
    border-radius: 50%;
    overflow: hidden;
    background: #eb4911;
    z-index: 2;
    animation: move 0.3s 6s ease forwards;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      background: url(assets/Gradient.png);
      background-size: cover;
      opacity: 0.2;
      mix-blend-mode: lighten;
    }
  }
}

@keyframes move {
  0% {
    transform: scale(1);
    bottom: -200vh;
    top: 15% !important;
  }
  100% {
    bottom: -55vh;
    top: 46% !important;
    transform: scale(1);
  }
}

.leaves {
  position: absolute;
  // width: 800%;
  height: 200px;
  bottom: -41px;
  left: -100% !important;
  right: -100% !important;
  margin: auto;
  // overflow: hidden;
  white-space: nowrap;
  top: auto !important;
  z-index: 9;

  .leave {
    text-indent: 0px;
    display: inline-block;
    margin-right: 2px;
  }
}
@keyframes changeLayer {
  0% {
    z-index: 6;
    transform: translateY(0px);
  }
  30% {
    z-index: 6;
    transform: translateY(700px);
  }
  40% {
    z-index: 1;
    transform: translateY(700px);
  }
  100% {
    z-index: 1;
    transform: translateY(80px);
  }
}
@keyframes changeLayerM {
  0% {
    z-index: 6;
    transform: translateY(0px);
  }
  30% {
    z-index: 6;
    transform: translateY(700px);
  }
  40% {
    z-index: 1;
    transform: translateY(700px);
  }
  100% {
    z-index: 1;
    transform: translateY(-150px);
  }
}

.flowers {
  position: absolute;
  display: block;
  top: auto !important;
  width: 100%;
  height: 360px;
  bottom: 5%;
  z-index: 6;
  text-align: center;
  white-space: nowrap;
  animation: changeLayer 2s 5.8s ease forwards;
  @media screen and (max-width: 960px) {
    top: 60% !important;
    margin: 0 auto;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }

  .flower {
    position: relative;
    display: inline-block;
    width: 340px;
    height: 340px;
    margin: 0;
    text-align: center;
    transform-origin: center;

    &:nth-child(1) {
      margin-top: -50px;
      z-index: 2;
    }

    &:nth-child(2) {
      z-index: 0;
    }
    &:nth-child(3) {
      z-index: 0;
    }
    &:nth-child(4) {
      margin-top: -50px;
    }
    @media screen and (max-width: 960px) {
      // &:nth-child(1) {
      //   top: -160px;
      //   left: 106px;
      // }

      // &:nth-child(2) {
      //   top: -80px;
      //   left: 8px;
      // }
      // &:nth-child(3) {
      //   // top: -160px;
      //   // left: 92px;
      // }
      // &:nth-child(4) {
      //   top: -160px;
      //   left: -116px;
      // }
    }
  }
}

.sparks {
  width: 100%;
  z-index: 4;
}

.spark {
  position: absolute;
  width: 151px;
  height: 151px;
  z-index: 2;
  top: 200px;
  left: 0;
  right: 0;
  margin: auto;

  &:first-of-type {
    top: 100px;
    left: -50%;
  }

  &:last-of-type {
    top: 100px;
    right: -50%;
  }
}

.left-leafs,
.right-leafs {
  display: inline-block;
  animation-delay: 0.5s;
}

@media screen and (min-width: 1200px) {
  .left-leafs {
    animation: leave-space-left 1s 6s ease forwards;
  }
  .right-leafs {
    animation: leave-space-right 1s 6s ease forwards;
  }
}

@keyframes leave-space-left {
  0% {
    transform-origin: 0% 0%;
    transform: translateX(0px);
  }
  1% {
    transform-origin: 100% 50%;
    transform: translateX(0px);
  }
  100% {
    transform-origin: 100% 50%;
    transform: translateX(-20vw);
  }
}
@keyframes leave-space-right {
  0% {
    transform-origin: 0% 0%;
    transform: translateX(0px);
  }
  1% {
    transform-origin: 0% 50%;
    transform: translateX(0px);
  }
  100% {
    transform-origin: 0% 50%;
    transform: translateX(20vw);
  }
}

@media screen and (max-width: 960px) {
  main {
    flex-direction: column;
    margin-bottom: 45px;
  }
  aside {
    width: 100%;
    height: 30px;
    border-right: 0px;
    border-bottom: 2px solid #c99d52;
    background: url(assets/aja-logo-m.png) repeat-x left center;
    background-size: auto 16px;
    animation: bgMarqueeM 5s linear infinite;
  }

  main .circle {
    width: 100vw;
    height: 100vw;
    top: 50% !important;
  }

  .leaves {
    // height: 70px;
    bottom: -55px !important;
    // z-index: 7;
    .leave-container {
      left: -13px;
      top: -13px;
    }
    .leave {
      width: 70px;
      height: 70px;
    }
    .leave-container,
    .leave-box,
    .leave .gold,
    .leave .green {
      width: 100px;
      height: 100px;
    }
  }
  .spark {
    width: 70px;
    height: 70px;
  }

  .flowers {
    bottom: 60px;
    height: 100px;
    animation: changeLayerM 2s 5.8s ease forwards;
    .flower {
      // margin: 0 6px;
      width: 100px;
      height: 100px;
    }
  }

  #app {
    footer {
      position: fixed;
      width: 100%;
      max-width: 100%;
      height: 45px;
      overflow: hidden;
      bottom: 0;
      transition: height 0.3s ease;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0;

      > div {
        line-height: 45px;
      }
      ul {
        position: relative;
        flex-direction: column;
        max-width: 100%;
        padding: 0;
        margin: 0;
        li {
          margin: 20px 0 0;
          display: block;
          text-align: center;

          > a {
            height: auto;
            line-height: 1.35;
          }

          &::before {
            display: block;
            margin: 0 auto 22px;
          }
        }
      }
      &.is-open {
        height: calc(100% - 50px);

        > div {
          margin-top: 20%;
        }
        ul li a {
          max-width: 296px;
          padding: 0 16px 6px;
          font-size: 16px;
          font-weight: 400;
        }
        .close {
          position: fixed;
          display: block;
          bottom: 40px;
          width: 48px;
          margin: 0 auto;
          height: 48px;
          border: 2px solid #c99d52;
          border-radius: 50%;
          background: none;

          &::before,
          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transform-origin: 50%;
            margin: auto;
            width: 32px;
            height: 2px;
            background: #c99d52;
            background-size: 24px;
          }

          &::before {
            transform: rotate(45deg);
          }
          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
</style>
