<template>
  <div class="spark">
    <svg viewBox="0 0 215 215" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-1603.000000, -284.000000)" fill="#C99D52">
              <path d="M1635,316 C1660.16667,328.08 1685.33333,334.12 1710.5,334.12 C1735.66667,334.12 1760.83333,328.08 1786,316 C1773.92,341.166667 1767.88,366.333333 1767.88,391.5 C1767.88,416.666667 1773.92,441.833333 1786,467 C1760.83333,454.92 1735.66667,448.88 1710.5,448.88 C1685.33333,448.88 1660.16667,454.92 1635,467 C1647.08,441.833333 1653.12,416.666667 1653.12,391.5 C1653.12,366.333333 1647.08,341.166667 1635,316 Z" id="Rectangle-Copy-5" transform="translate(1710.500000, 391.500000) rotate(-315.000000) translate(-1710.500000, -391.500000) "></path>
          </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Spark'
}
</script>


<style lang="sass">
$delay1: random(5)
$delay2: random(7)
$delay3: random(3)

@keyframes sparkle 
  0%
    transform: rotate(0deg) scale(0)
  
  30%
    transform: rotate(0deg) scale(1)
  100% 
    transform: rotate(360deg) scale(0)
  

.spark 
  transform: rotate(0deg) scale(0)
  animation: sparkle 1.5s .65s cubic-bezier(.65,.1,.43,1.2) forwards infinite

  &:first-of-type
    animation: sparkle 1s 1s cubic-bezier(.65,.1,.43,1.2) forwards infinite

  &:last-of-type
    animation: sparkle 2s .3s cubic-bezier(.65,.1,.43,1.2) forwards infinite

</style>