<template>
  <div class="flower is-pink">
    <svg viewBox="0 0 360 360" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="38.3849558%" gradientTransform="translate(0.500000,0.500000),rotate(90.000000),scale(1.000000,0.971365),translate(-0.500000,-0.500000)" id="pinl-radialGradient-1">
                <stop stop-color="#FF9F96" offset="0%"></stop>
                <stop stop-color="#FFEBE7" offset="100%"></stop>
            </radialGradient>
            <path d="M90,270 C40.2943725,270 -2.84217094e-14,229.705627 -2.84217094e-14,180 C0,130.294373 40.2943725,90 90,90 L90,90 L90.0120574,88.5116854 C90.8068286,39.4925145 130.791429,0 180,0 C229.705627,0 270,40.2943725 270,90 C319.705627,90 360,130.294373 360,180 C360,229.705627 319.705627,270 270,270 C270,319.705627 229.705627,360 180,360 C130.294373,360 90,319.705627 90,270 L90,270 Z" id="pink-path-2"></path>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="flower-pink">
                <g >
                    <use fill="url(#pinl-radialGradient-1)" xlink:href="#pink-path-2"></use>
                    <use fill="#FFEFEC" xlink:href="#pink-path-2"></use>
                </g>
                <g transform="translate(180.000000, 180.000000) rotate(-360.000000) translate(-180.000000, -180.000000) translate(116.000000, 116.000000)" stroke="#AB2128" stroke-linecap="square" stroke-width="3">
                    <line x1="64.3535534" y1="0.642385293" x2="64.3535534" y2="31.8981992" id="Line-2"></line>
                    <line x1="111.729708" y1="47.6464466" x2="111.729708" y2="79.6464466" id="Line-2" transform="translate(111.729708, 63.646447) rotate(90.000000) translate(-111.729708, -63.646447) "></line>
                    <line x1="63.6464466" y1="96.1018008" x2="63.6464466" y2="127.357615" id="Line-2" transform="translate(63.646447, 111.729708) rotate(180.000000) translate(-63.646447, -111.729708) "></line>
                    <line x1="16.2702923" y1="48.3535534" x2="16.2702923" y2="80.3535534" id="Line-2" transform="translate(16.270292, 64.353553) rotate(270.000000) translate(-16.270292, -64.353553) "></line>
                </g>
            </g>
        </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'FlowerPink'
}
</script>

<style lang="scss">
@keyframes speedRotate2 {
  0% {
    transform: rotate(0deg);
  }  
  40% {
    transform: rotate(-180deg);
  }  
  70% {
    transform: rotate(360deg);
  }  
  100% {
    transform: rotate(00deg);
  }  
}

.flower.is-pink {
  animation: speedRotate2 15s ease forwards infinite;
}


</style>